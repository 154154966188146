import React, { Component } from 'react';
import 'fontsource-roboto';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

class DiagComponent extends Component {
    static displayName = DiagComponent.name;

    render() {

        return (
            <div>
                <Grid container spacing={2} style={{ marginTop: 20 }} justify="center" alignItems="center">
                    <Grid item xs={6}>
                        <Grid container justify="center" spacing={2} direction="column">
                            <Grid key={0} item>
                                <Typography gutterBottom component="h6" variant="h5" align="center">Diagnostics</Typography>
                            </Grid>
                            <Grid key={1} item >
                                <Typography gutterBottom component="h2" variant="subtitle2" align="center" color="textPrimary">
                                    At Center Street, we use the best technology in the business. Our machines speed up work time, protect your vehicle, and get you back on the road faster than ever before.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" alignItems="center" spacing={2}>
                            <Grid key={0} item >
                                <img src="/images/learn_more/Diag1.jpeg" alt="diagnostics" width="350px" height="250px" />
                            </Grid>
                            <Grid key={1} item >
                                <img src="/images/learn_more/diag3.jpeg" alt="diagnostics" width="350px" height="250px" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default DiagComponent;
