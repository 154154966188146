import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

const ProgressBar = (props) => {

    return(
        <Dialog
          open={props.loading}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
          <Fade in={props.loading} style={{ transitionDelay: props.loading ? '100ms' : '0ms', }} unmountOnExit>
            <CircularProgress />
          </Fade>
          </DialogContent>
        </Dialog>
    )
}

export default ProgressBar