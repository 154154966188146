import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'fontsource-roboto';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { COLORS, ReCaptchaKey } from '../helper';
import { FormControl } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import ProgressBar from '../components/ProgressBar';
import MessageBox from '../components/MessageBox';
import ReCAPTCHA from 'react-google-recaptcha';
import '../custom.css';

moment.locale('en-US');

//const recaptchaRef = createRef();

const styles = theme => ({
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: 240,
    },
    content: {
        flexGrow: 1,
        marginTop: 75,
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${240}px)`,
            marginLeft: 240,
        },
    },
});

//const redborder = {
//    borderColor: 'red'
//}

class Appointments extends Component {
    static displayName = Appointments.name;

    constructor(props) {
        super(props)
        this.state = {
            FullName: '',
            Email: '',
            PhoneNumber: '',
            CompanyName: '',
            Address: '',
            City: '',
            State: '',
            ZipCode: '',
            Description: '',
            openDialog: false,
            message: '',
            dialogTitle: '',
            Service: '',
            services: [],
            disableDate: true,
            disableTime: true,
            selectedDate: '',
            selectedTime: '',
            availableTimes: [],
            loading: false,
            disableSubmit: true,
            nameError: false,
            emailError: false,
            phoneError: false,
            addressError: false,
            cityError: false,
            stateError: false,
            zipError: false,
            serviceError: false,
            dateError: false,
            timeError: false,
            captchaToken: '',
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const loadServices = async () => {
            try {
                //const response = await axios.get('api/Services');
                const response = await axios.get('https://cs-app.hibbsinc.com/api/Services');
                this.setState({ services: response.data });

            } catch (error) {
                console.log(error);
            }
        }

        loadServices();
    }

    validateInputs = () => {
        var errors = 0;

        if (this.state.FullName.length === 0) {
            this.setState({ nameError: true })
            errors++;
        } else {
            this.setState({ nameError: false })
        }
        if (this.state.Email.length === 0) {
            this.setState({ emailError: true });
            errors++;
        } else {
            this.setState({ emailError: false })
        }
        if (this.state.PhoneNumber.length === 0) {
            this.setState({ phoneError: true })
            errors++;
        } else {
            this.setState({ phoneError: false })
        }
        if (this.state.Address.length === 0) {
            this.setState({ addressError: true })
            errors++;
        } else {
            this.setState({ addressError: false })
        }
        if (this.state.City.length === 0) {
            this.setState({ cityError: true })
            errors++;
        } else {
            this.setState({ cityError: false })
        }
        if (this.state.State.length === 0) {
            this.setState({ stateError: true })
            errors++;
        } else {
            this.setState({ stateError: false })
        }
        if (this.state.ZipCode.length === 0) {
            this.setState({ zipError: true })
            errors++;
        } else {
            this.setState({ zipError: false })
        }
        if (this.state.Service.length === 0) {
            this.setState({ serviceError: true })
            errors++;
        } else {
            this.setState({ serviceError: false })
        }
        if (this.state.selectedDate.length === 0) {
            this.setState({ dateError: true })
            errors++;
        } else {
            this.setState({ dateError: false })
        }
        if (this.state.selectedTime.length === 0) {
            this.setState({ timeError: true })
            errors++;
        } else {
            this.setState({ timeError: false })
        }

        if (errors > 0) {
            this.setState({
                openDialog: true,
                msgtitle: 'Missing Information',
                message: 'Some required fields are empty. Please make sure to fill in all required fields.',
                loading: false,
            });
            return false;
        }

        return true;
    }

    onCaptchaCheck = (value) => {
        if (value !== null) {
            this.setState({ disableSubmit: false, captchaToken: value });
        }
    }

    onCaptchaExpired = (value) => {
        this.setState({ disableSubmit: true, captchaToken: null });
    }

    onChange = (evt) => {
        this.setState({
            [evt.target.name]: evt.target.value
        })

        if (evt.target.name === 'Service') {
            this.setState({ disableDate: false });
        }
    }

    register = () => {
        this.setState({ loading: true });
        //recaptchaRef.current.execute();

        if (!this.validateInputs()) {
            return;
        }

        if (this.state.captchaToken === null) {
            this.setState({
                openDialog: true,
                msgtitle: 'Captcha Error',
                message: 'You must complete the reCAPTCHA verification.',
            });
            return;
        }

        const sendAppointment = async () => {
            try {
                const body = {
                    'FullName': this.state.FullName,
                    'Email': this.state.Email,
                    'PhoneNumber': this.state.PhoneNumber,
                    'Address': this.state.Address,
                    'City': this.state.City,
                    'State': this.state.State,
                    'ZipCode': this.state.ZipCode,
                    'Service': this.state.Service,
                    'Description': this.state.Description,
                    'SelectedDate': this.state.selectedDate,
                    'SelectedTime': this.state.selectedTime,
                };
                let header = { 'api-key': this.state.captchaToken };
                //const response = await axios.post('api/Events', body, { headers: header });

                //console.log(response.data);
                await axios.post('https://cs-app.hibbsinc.com/api/Events', body, { headers: header });

                this.setState({
                    loading: false,
                    message: 'Your appointment has been scheduled. Thank you for your business!',
                    openDialog: true,
                    FullName: '',
                    Email: '',
                    PhoneNumber: '',
                    Address: '',
                    City: '',
                    State: '',
                    ZipCode: '',
                    Description: '',
                    Service: '',
                    disableDate: true,
                    disableTime: true,
                    disableSubmit: true,
                    availableTimes: [],
                    selectedDate: '',
                    selectedTime: '',
                    captchaToken: null,
                });
                this.captcha.reset();

            } catch (error) {
                console.log(error);
                this.setState({
                    loading: false,
                    message: 'We could not schedule your appointment at this time. Please try again or call 270-825-1635.',
                    openDialog: true
                });
            }
        }

        sendAppointment();
    }

    selectDate = (evt) => {

        if (evt.target.name === 'selectedDate') {
            let currentDate = moment().format('l');
            let selectedDate = moment(evt.target.value).format('l');

            if (selectedDate < currentDate) {
                this.setState({
                    openDialog: true,
                    msgtitle: 'Error',
                    message: 'Selected date is in the past. Please select another date.',
                    selectedDate: '',
                    availableTimes: [],
                    disableTime: true,
                });
                return;
            }
        }

        this.setState({
            [evt.target.name]: evt.target.value,
            loading: true,
        })

        const loadTimes = async () => {
            try {
                let url = 'https://cs-app.hibbsinc.com/api/Events/GetTimes/' + evt.target.value + '/' + this.state.Service;

                if (this.state.Service > 3) {
                    url = 'https://cs-app.hibbsinc.com/api/Events/GetServiceTimes/' + evt.target.value;
                }

                const response = await axios.get(url);
                //console.log(response.data);
                let times = response.data;
                for (let i = 0; i < times.length; i++) {
                    times[i] = moment(times[i]).format('LT');
                }
                this.setState({ availableTimes: times, loading: false });

            } catch (error) {
                console.log(error);
            }
        }

        loadTimes();
        this.setState({ disableTime: false });
    }

    resetDateTime = () => {
        this.setState({
            selectedDate: '',
            selectedTime: '',
            Service: '',
            disableDate: true,
            disableTime: true,
            disableSubmit: true
        });
    }

    handleCloseDialog = () => {
        this.setState({
            openDialog: false,
            msgtitle: '',
            message: '',
        });
    };

    states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana',
        'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska',
        'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
        'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];

    render() {
        //const { classes } = this.props;

        return (
            <Box display="flex" alignItems="center" justifyContent="center" marginTop="20px">
                <div>
                    <ProgressBar loading={this.state.loading} />
                </div>
                <div><MessageBox message={this.state.message} title={this.state.msgtitle} openDialog={this.state.openDialog} handleClose={this.handleCloseDialog} /></div>
                <Card style={{ maxWidth: 600 }}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h2" align="center">
                            Schedule an appointment today!
                        </Typography>
                        <TextField id="name" name="FullName" error={this.state.nameError} required={true} label="Name" value={this.state.FullName} onChange={this.onChange} variant="outlined" style={{ marginBottom: 7, marginTop: 7, width: '100%' }} />
                        <TextField id="email" name="Email" error={this.state.emailError} required={true} label="Email" value={this.state.Email} type="email" onChange={this.onChange} style={{ marginBottom: 7, width: '100%' }} variant="outlined" />
                        <TextField type="tel" inputProps={{ maxLength: 10 }} id="phone" name="PhoneNumber" error={this.state.phoneError} required={true} label="Phone" value={this.state.PhoneNumber} onChange={this.onChange} style={{ marginBottom: 7, width: '100%' }} variant="outlined"/>
                        <TextField id="address" name="Address" error={this.state.addressError} required={true} label="Address" value={this.state.Address} onChange={this.onChange} style={{ marginBottom: 7, width: '100%' }} variant="outlined" />
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField id="city" name="City" error={this.state.cityError} required={true} label="City" value={this.state.City} onChange={this.onChange} style={{ marginBottom: 7 }} variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" required={true} error={this.state.stateError} style={{ width: '100%' }}>
                                    <InputLabel id="state-select-label" style={{ textAlign: 'left' }}>State</InputLabel>
                                    <Select
                                        labelId="state-select-label"
                                        id="state-select"
                                        name="State"
                                        value={this.state.State}
                                        onChange={this.onChange}
                                        style={{ width: '90%', textAlign: 'left' }}

                                    >
                                        {this.states.map((state) => (
                                            <MenuItem key={state} value={state} alignItems="flex-start">{state}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="zip" required={true} inputProps={{ maxLength: 5 }} error={this.state.zipError} name="ZipCode" label="Zip Code" value={this.state.ZipCode} type="number" onChange={this.onChange} style={{ marginBottom: 7 }} variant="outlined" />
                            </Grid>
                        </Grid>
                        <FormControl variant="outlined" required={true} error={this.state.serviceError} style={{ width: '100%' }}>
                            <InputLabel id="service-select-label" style={{ textAlign: 'left' }}>Select Service</InputLabel>
                            <Select
                                labelId="service-select-label"
                                id="service-select"
                                name="Service"
                                value={this.state.Service}
                                onChange={this.onChange}
                                style={{ width: '100%', textAlign: 'left' }}
                            >
                                {this.state.services.map((service) => (
                                    <MenuItem key={service.id} value={service.id} alignItems="flex-start">{service.description}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <TextField
                                        required={true}
                                        error={this.state.dateError}
                                        id="date"
                                        name="selectedDate"
                                        value={this.state.selectedDate}
                                        label="Date"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={this.selectDate}
                                        disabled={this.state.disableDate}
                                        style={{ marginTop: 7, marginRight: 20, maxWidth: 350 }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" required={true} error={this.state.timeError} style={{ width: '100%' }}>
                                        <InputLabel id="time-select-label" style={{ textAlign: 'left' }}>Time</InputLabel>
                                        <Select
                                            labelId="time-select-label"
                                            error={this.state.timeError}
                                            id="time-select"
                                            name="selectedTime"
                                            value={this.state.selectedTime}
                                            onChange={this.onChange}
                                            disabled={this.state.disableTime}
                                            style={{ textAlign: 'left', marginTop: 7, height: 45 }}

                                        >
                                            {this.state.availableTimes.map((time) => (
                                                <MenuItem key={time} value={time} alignItems="flex-start">{time}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button size="small" onClick={this.resetDateTime} color="primary">Reset</Button>
                                </Grid>
                            </Grid>
                        </form>
                        <TextField id="description" variant="outlined" name="Description" label="Description of service needed" multiline rows="5" value={this.state.Description} onChange={this.onChange} style={{ marginTop: 7, marginBottom: 3, width: '100%' }} />
                        <ReCAPTCHA
                            sitekey={ReCaptchaKey}
                            onChange={this.onCaptchaCheck}
                            onExpired={this.onCaptchaExpired}
                            ref={el => { this.captcha = el; }}
                            title="verify"
                        />
                    </CardContent>
                    <CardActions>
                        <Button size="large" style={{ margin: 'auto', backgroundColor: COLORS.Red, color: 'white', width: 150 }} disabled={this.state.disableSubmit} onClick={this.register}>Submit</Button>
                    </CardActions>
                </Card>
            </Box>
        );
    }
}

Appointments.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Appointments);
