import React, { Component } from 'react';
import 'fontsource-roboto';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

class AlignmentComponent extends Component {
    static displayName = AlignmentComponent.name;

    render() {

        return (
            <div>
                <Grid container spacing={2} style={{ marginTop: 20 }} justify="center" alignItems="center">
                    <Grid item xs={8}>
                        <Grid container justify="center" spacing={2} direction="column">
                            <Grid key={0} item>
                                <Typography gutterBottom component="h6" variant="h5" align="center">Alignment</Typography>
                            </Grid>
                            <Grid key={1} item>
                                <Typography gutterBottom component="h2" variant="subtitle2" align="center" color="textPrimary">
                                    At Center Street, we offer a state-of-the-art alignment experience. Our machines increase efficiency, protect your vehicle, and get you back on the road faster than ever before.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" alignItems="center" spacing={2} direction="column">
                            <Grid key={0} item >
                                <img src="/images/learn_more/IMG_6140.JPG" alt="oil" width="300px" height="300px" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 20 }} justify="center" alignItems="center">
                    <Grid item xs={8}>
                        <Grid container justify="center" spacing={2} direction="column">
                            <Grid key={0} item>
                                <Typography gutterBottom component="h2" variant="subtitle2" align="left" color="textPrimary">
                                    Why is alignment so important? Vehicle alignment is important for three main reasons:
                                </Typography>
                                <List >
                                    <ListItem>
                                        <Typography component="h2" variant="subtitle2" color="textPrimary" >
                                            1.	Proper alignment preserves and protects the life of your tires
                                  </Typography>
                               </ListItem>
                                    <ListItem>
                                        <Typography component="h2" variant="subtitle2" color="textPrimary" >
                                            2.	A properly aligned vehicle is much safer to drive as it does not pull to the left or right
                                  </Typography>
                               </ListItem>
                                    <ListItem>
                                        <Typography component="h2" variant="subtitle2" color="textPrimary" >
                                            3.	Proper alignment helps improve overall ride quality
                                  </Typography>
                               </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" alignItems="center" spacing={2} direction="column">
                            <Grid key={0} item >
                                <img src="/images/learn_more/IMG_0767.jpeg" alt="oil" width="300px" height="300px" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default AlignmentComponent;
