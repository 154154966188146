import React, { Component } from 'react';
import 'fontsource-roboto';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

class RepairComponent extends Component {
    static displayName = RepairComponent.name;

    render() {

        return (
            <div>
                    <Grid container spacing={2} style={{ marginTop: 20 }} justify="center" alignItems="center">
                        <Grid item xs={10}>
                            <Grid container justify="center" spacing={2} direction="column">
                                <Grid key={0} item>
                                    <Typography gutterBottom component="h6" variant="h5" align="center">Service/Repair</Typography>
                                </Grid>
                                <Grid key={1} item>
                                    <Typography gutterBottom component="h2" variant="subtitle2" align="center" color="textPrimary">
                                        One of the more popular questions we get asked is “Hey, Center Street, what type of oil should I be putting in my car?”
                                    </Typography>
                                </Grid>
                                <Grid key={2} item>
                                <Typography gutterBottom component="h2" variant="subtitle2" align="center" color="textPrimary">
                                        There’s essentially two main types of oil that fit the average consumer’s needs: Conventional and Synthetic.
                                    </Typography>
                                </Grid>
                                <Grid key={3} item>
                                <Typography gutterBottom component="h2" variant="subtitle2" align="center" color="textPrimary">
                                        Full synthetic oil is most commonly used for vehicles that demand peak level performance. This type of oil provides resistance to oxidation, and helps prevent oil sludge. It’s also been helpful in improving vehicle horsepower and reducing drag.
                                    </Typography>
                                </Grid>
                                <Grid key={4} item>
                                <Typography gutterBottom component="h2" variant="subtitle2" align="center" color="textPrimary">
                                        Conventional oil is the most commonly used engine oil as it is ideal for light-duty, later model cars with average mileage.
                                    </Typography>
                                </Grid>
                                <Grid key={5} item>
                                <Typography gutterBottom component="h2" variant="subtitle2" align="center" color="textPrimary">
                                        The third option is a blend of the two. It’s cheaper than full synthetic oil, but carries a good amount of the benefits. This is a great option for drivers who want more protection out of their engine oil without the higher cost.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="center" alignItems="center" spacing={2} direction="column">
                                <Grid key={0} item >
                                    <img src="/images/learn_more/IMG_6134.JPG" alt="oil" width="300px" height="300px" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: 20 }} justify="center" alignItems="center">
                        <Grid item xs={10}>
                            <Grid container justify="center" spacing={2} direction="column">
                                <Grid key={0} item>
                                    <Typography gutterBottom component="h2" variant="subtitle2" align="center" color="textPrimary">
                                        Did you know that we work on fleet vehicles too? Do you have a business with multiple vehicles that need frequent service and upkeep? We’ve got you covered!
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="center" alignItems="center" spacing={2} direction="column">
                                <Grid key={0} item >
                                    <img src="/images/learn_more/IMG_6136.JPG" alt="oil" width="300px" height="300px" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            </div>
        );
    }
}

export default RepairComponent;
