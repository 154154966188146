import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { COLORS } from '../helper';

const MessageBox = (props) => {

    return(
        <Dialog
          open={props.openDialog}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.message}
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={props.handleClose} style={{ backgroundColor: COLORS.Red, color: 'white' }} autoFocus>
              OK
          </Button>
          </DialogActions>
        </Dialog>
    )
}

export default MessageBox