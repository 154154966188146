import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import '../custom.css';

const Hours = () => {

    

    return (
        <div >
            <Grid container spacing={2} style={{ marginTop: 20 }} justify="center" alignItems="center" direction="column">
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={8}>
                        <Grid key={0} item>
                            <Typography>Center Street Service, Wheel & Tire</Typography>
                            <Typography><a href="http://maps.apple.com/?daddr=37.327895,-87.483695" target="_blank" rel="noopener noreferrer">751 E. Center Street<br />Madisonville, KY 42431</a></Typography>
                            <Typography>(270) 825-1635</Typography>
                            <Typography>Hours:</Typography>
                            <Typography>Mon-Fri: 8:00am - 5:00pm</Typography>
                            <Typography>Sat: 8:00am - 12:00pm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Hours;