import React, { Component } from 'react';
import { IconButton } from '@material-ui/core';
import { Facebook, Instagram } from '@material-ui/icons';
import { COLORS } from '../helper'; 
import './NavMenu.css';

export class Footer extends Component {
  static displayName = Footer.name;

  constructor (props) {
    super(props);

    }

  render () {
    return (
        <div style={{height: '50px', backgroundColor: COLORS.Red, marginTop: 10}}>
            <center>
                <IconButton size="large" variant='text' href='https://www.facebook.com/centerstreetservice?mibextid=LQQJ4d' >
                    <Facebook fontSize='large'/>
                </IconButton>
                <IconButton size="Large" variant='text' href='https://instagram.com/centerstreetservice?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr'>
                    <Instagram fontSize='large'/>
                </IconButton>
                </center>
        </div>
    );
  }
}
