import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'fontsource-roboto';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HeaderImage from './HeaderImage';
import Services from './Services';
import Testimonials from './Testimonials';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const styles = theme => ({
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: 240,
    },
    content: {
        flexGrow: 1,
        marginTop: 75,
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${240}px)`,
            marginLeft: 240,
        },
    },
});



class StaticHeader extends Component {
    static displayName = StaticHeader.name;

    constructor(props) {
        super(props)

        this.state = {
            open: false,
        }
    }

    videoEnded = () => {
        this.handleClose();
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        //const { classes } = this.props;

      return (
          <div>
              
              <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
                  <DialogContent>
                      <video controls autoPlay onEnded={this.videoEnded}>
                          <source src="/videos/vid2.mp4" type="video/mp4" />
                          Your browser does not support the video tag.
                      </video>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={this.handleClose} color="primary">Close</Button>
                  </DialogActions>
              </Dialog>
              <HeaderImage></HeaderImage>
              <Grid container spacing={2} style={{ marginTop: 20 }}>
                  <Grid item xs={12}>
                      <Grid container justify="center" spacing={2}>
                          <Grid key={0} item>
                              <div style={{ maxWidth: 600 }}>
                                  <Typography gutterBottom component="h2" variant="h5" align="center" color="textSecondary">
                                      We put the "Service" in Center Street! Our well-trained, experienced technicians use state-of-the-art equipment to maximize the performance and lifespan of your vehicle. We also offer a wide variety of wheels, tires and vehicle accessories.
                                </Typography>
                              </div>
                          </Grid>
                      </Grid>
                  </Grid>
              </Grid>
              <a href="/appointments"><Typography gutterBottom component="h2" variant="h4" align="center">Schedule an appointment today.</Typography></a>
              <Services></Services>
              <Grid container spacing={2} style={{ marginTop: 20 }}>
                  <Grid item xs={12}>
                      <Grid container justify="center" spacing={2}>
                          <Grid key={0} item>
                              <div style={{ maxWidth: 600 }}>
                                  <Typography gutterBottom component="h2" variant="h5" align="center" color="textSecondary">
                                      We put the "Service" in Center Street! Our well-trained, experienced technicians use state-of-the-art equipment to maximize the performance and lifespan of your vehicle. We also offer a wide variety of wheels, tires and vehicle accessories.
                                </Typography>
                              </div>
                          </Grid>
                      </Grid>
                  </Grid>
              </Grid>
              <Testimonials></Testimonials>
          </div>
    );
  }
}

StaticHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StaticHeader);