import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import '../custom.css';

const Testimonials = () => {

    const paperWidth = "300px";
    const imageWidth = "275px";

    return (
        <div >
            <Grid container spacing={2} style={{ marginTop: 20 }}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={2}>
                        <Grid key={0} item>
                            <Paper style={{ width: paperWidth, padding: 10 }}>
                                <img src="/images/reviews/review1.jpg" alt="Review" width={imageWidth} />
                            </Paper>
                        </Grid>
                        <Grid key={1} item>
                            <Paper style={{ width: paperWidth, padding: 10 }}>
                                <img src="/images/reviews/review2.jpg" alt="Review" width={imageWidth} />
                            </Paper>
                        </Grid>
                        <Grid key={2} item>
                            <Paper style={{ width: paperWidth, padding: 10 }}>
                                <img src="/images/reviews/review3.jpg" alt="Review" width={imageWidth} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Testimonials;