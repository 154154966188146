import React, { Component } from 'react';
import 'fontsource-roboto';
import Hours from '../components/Hours';
import Grid from '@material-ui/core/Grid';

class Directions extends Component {
    static displayName = Directions.name;

    render() {

        return (
            <div>
                <div>
                    <Hours />
                </div>
                <Grid container spacing={2} style={{ marginTop: 20 }} justify="center" alignItems="center" direction="column">
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={8}>
                            <Grid key={1} item >
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.6267348534307!2d-87.48620498473704!3d37.327668479843375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886fff9d5479fe83%3A0x284c485a668776df!2sCenter%20Street%20Service%2C%20Wheel%20%26%20Tire!5e0!3m2!1sen!2sus!4v1600708295481!5m2!1sen!2sus" width="600px" height="450px" title="directions" style={{ border: 0 }}></iframe>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Directions;
