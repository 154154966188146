import React from 'react';
import { Zoom } from 'react-slideshow-image';
//import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import '../custom.css';

const ImageSlideShow = () => {
    const fadeImages = [
        "/images/IMG_0769_cropped.jpg",
        "/images/IMG_0770_cropped.jpg",
        "/images/IMG_0771_cropped.jpg",
        "/images/IMG_0772_cropped.jpeg"
    ];

    const imageWidth = "100%";
    const imageHeight = "400px";

    return (
        <div className='slid-container'>
            <Zoom scale={0.4}>
                {
                    fadeImages.map((each, index) => <img key={index} style={{width: imageWidth, height: imageHeight}} src={each} />)
                }
            </Zoom>
        </div>
      //   <div className="slide-container">
      //   <Fade>
      //     {
      //       fadeImages.map((each, index) => <img key={index} style={{width: imageWidth, height: imageHeight}} src={each} />)
      //     }
      //   </Fade>
      // </div>
        // <div >
        //     <Fade>
        //         <div className="each-slide">
        //             <div style={{ 'backgroundImage': `url(${fadeImages[0]})`, height: { imageHeight }, width: { imageWidth } }}>
        //                 <span>WE PUT THE "SERVICE" IN CENTER STREET</span>
        //             </div>
        //         </div>
        //         <div className="each-slide">
        //             <div style={{ 'backgroundImage': `url(${fadeImages[1]})`, height: { imageHeight }, width: { imageWidth } }}>
        //         </div>
        //         </div>
        //         <div className="each-slide">
        //             <div style={{ 'backgroundImage': `url(${fadeImages[2]})`, height: { imageHeight }, width: { imageWidth } }}>
        //             </div>
        //         </div>
        //         <div className="each-slide">
        //             <div style={{ 'backgroundImage': `url(${fadeImages[3]})`, height: { imageHeight }, width: { imageWidth } }}>
        //             </div>
        //         </div>
        //     </Fade>
        // </div>
    );
};

export default ImageSlideShow;