import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { COLORS } from '../helper';
import '../custom.css';

const Services = () => {

    return (
        <div >
            <Grid container spacing={2} style={{ marginTop: 20 }}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={2}>
                        <Grid key={0} item>
                            <Card style={{ maxWidth: 300 }}>
                                <CardMedia component="img" alt="Service" height="175" image="/images/service.jpg" title="Service" />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Service/Repair
                                        </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        One of the more popular questions we get asked is “Hey, Center Street, what type of oil should I be putting in my car?”
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button href="/repair" size="small" style={{ color: COLORS.Red }}>Learn More</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid key={1} item>
                            <Card style={{ maxWidth: 300 }}>
                                <CardMedia component="img" alt="Wheels" height="175" image="/images/wheels.jpg" title="Wheels" />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Wheels/Tires
                                        </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        When should you rotate your tires? As a rule of thumb, tires should be rotated every 5,000 to 7,000 miles.
                                        </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button href="/tires" size="small" style={{ color: COLORS.Red }}>Learn More</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid key={2} item>
                            <Card style={{ maxWidth: 300 }}>
                                <CardMedia component="img" alt="Alignment" height="175" image="/images/alignment.jpg" title="Alignment" />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Alignment
                                        </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Our alignments are optimized using four precision cameras to measure the position and orientation of targets...
                                        </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button href="/alignment" size="small" style={{ color: COLORS.Red }}>Learn More</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid key={3} item>
                            <Card style={{ maxWidth: 300 }}>
                                <CardMedia component="img" alt="Diagnostics" height="175" image="/images/diagnostics.jpg" title="Diagnostics" />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Diagnostics
                                        </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Our machines speed up work time, protect your vehicle, and get you back on the road faster than ever before. 
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button href="/diagnostics" size="small" style={{ color: COLORS.Red }}>Learn More</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Services;