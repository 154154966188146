import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import Directions from './components/Directions';
import StaticHeader from './components/StaticHeader';
import Appointments from './components/appointments';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import RepairComponent from './components/RepairComponent';
import DiagComponent from './components/DiagComponent';
import AlignmentComponent from './components/AlignmentComponent';
import TireComponent from './components/TireComponent';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/appointments' component={Appointments} />
            <Route path='/contactus' component={Directions} />
            <Route path='/static' component={StaticHeader} />

            <Route path='/repair' component={RepairComponent} />
            <Route path='/tires' component={TireComponent} />
            <Route path='/alignment' component={AlignmentComponent} />
            <Route path='/diagnostics' component={DiagComponent} />
        <AuthorizeRoute path='/counter' component={Counter} />
        <AuthorizeRoute path='/fetch-data' component={FetchData} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}
