import React from 'react';

function HeaderImage() {
    return (
        <div className="each-slide">
            <div style={{ 'backgroundImage': `url(/images/image1.jpg)`, width: '100%', height: '400px', objectFit: 'cover' }}>
                <span>WE PUT THE "SERVICE" IN CENTER STREET</span>
            </div>
        </div>
    );
}

export default HeaderImage;