import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
//import { LoginMenu } from './api-authorization/LoginMenu';
import { COLORS } from '../helper'; 
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
        collapsed: true,
        toggle: false,
    };
    }

    toggleDropDown = () => {
        this.setState({
            toggle: !this.state.toggle
        });
    }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm box-shadow" dark style={{ backgroundColor: COLORS.Red}}>
                <Container>
                    <NavbarBrand tag={Link} to="/" className="title-font"><img className="logo" src="/CSLogo-small.png" alt="logo" style={{ marginRight: 5, maxWidth: '75px' }}></img>Center Street Service, Wheel & Tire</NavbarBrand>

                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} className="text-light" to="/">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-light" to="/appointments">Schedule Service</NavLink>
                            </NavItem>
                            <NavItem>
                                <Dropdown isOpen={this.state.toggle} toggle={this.toggleDropDown} className="text-light">
                                    <DropdownToggle caret style={{ backgroundColor: COLORS.Red, border: 0, paddingBottom: -5 }}>
                                        Services
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem href="/repair">Service/Repair</DropdownItem>
                                        <DropdownItem href="/tires">Wheels/Tires</DropdownItem>
                                        <DropdownItem href="/alignment">Alignment</DropdownItem>
                                        <DropdownItem href="/diagnostics">Diagnostics</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-light" to="/contactus">Contact Us</NavLink>
                            </NavItem>
                            {/* <LoginMenu></LoginMenu> */}
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
  }
}
