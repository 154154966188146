import React, { Component } from 'react';
import 'fontsource-roboto';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

class TireComponent extends Component {
    static displayName = TireComponent.name;

    render() {

        return (
            <div>
                <Grid container spacing={2} style={{ marginTop: 20 }} justify="center" alignItems="center">
                    <Grid item xs={10}>
                        <Grid container justify="center" spacing={2} direction="column">
                            <Grid key={0} item>
                                <Typography gutterBottom component="h6" variant="h5" align="center">Wheels/Tires</Typography>
                            </Grid>
                            <Grid key={1} item>
                                <Typography gutterBottom component="h2" variant="subtitle2" align="center" color="textPrimary">
                                    Have you seen our huge selection of wheels and tires? We’ve got styles and sizes to match any vehicle!
                                    </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" alignItems="center" spacing={2}>
                            <Grid key={0} item >
                                <img src="/images/learn_more/IMG_6138.JPG" alt="wheels" width="250px" height="250px" />
                            </Grid>
                            <Grid key={2} item >
                                <img src="/images/learn_more/IMG_6144.JPG" alt="wheels" width="250px" height="250px" />
                            </Grid>
                            <Grid key={3} item >
                                <img src="/images/learn_more/IMG_6145.JPG" alt="wheels" width="250px" height="250px" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 20 }} justify="center" alignItems="center">
                    <Grid item xs={8}>
                        <Grid container justify="center" spacing={2} direction="column">
                            <Grid key={0} item>
                                <Typography gutterBottom component="h2" variant="subtitle2" align="left" color="textPrimary">
                                    When should you rotate your tires?
                                </Typography>
                                <Typography gutterBottom component="h2" variant="subtitle2" align="left" color="textPrimary">
                                    As a rule of thumb, tires should be rotated every 5,000 to 7,000 miles. Your owner’s manual will tell you more specific details and guidelines for your make and model. 
                                    Rotating your tires ensures that your tires are wearing evenly and that your car handles in a safe and efficient manner.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" alignItems="center" spacing={2} direction="column">
                            <Grid key={0} item >
                                <img src="/images/learn_more/IMG_6142.JPG" alt="oil" width="250px" height="250px" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default TireComponent;
